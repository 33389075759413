import React from 'react';
import { cn } from '@superside/ui';
import styles from '@konsus/apps-common/src/Header/HeaderDropButton/menus/HeaderSubmenu/styles.module.css';
import { BannerWithButton } from '@konsus/apps-common/src/Header/BannerWithButton';
import { type LearningCenterMenuItem } from './types';
import { MainItemsList } from './MainItemsList';
import { SecondaryItemsList } from './SecondaryItemsList';

export interface ResourcesMenuProps {
  menuItem: LearningCenterMenuItem;
}

export const ResourcesMenu: React.FC<ResourcesMenuProps> = (props) => {
  const { learningCenterData } = props.menuItem;
  const { banner, items, latestBlogPosts, latestCustomerStories } = learningCenterData;

  return (
    <div
      className={cn(
        'flex',
        'flex-row',
        'w-full',
        'pt-[9px]',
        '1280:bg-[#fafafa]',
        '1280:pt-0',
        '1280:shadow-[0px_0px_32px_0px_rgba(15,33,51,0.10)]',
        '1280:w-[1362px]',
        '1280:max-w-[1362px]',
        '1280:rounded-[8px]',
        '1280:relative',
        '1280:-left-5',
        '1280:mx-auto',
        '1280:my-0',
        '1280:overflow-hidden'
      )}
    >
      <div
        className={cn(
          ['w-full', 'bg-light', '1280:pl-10'],
          banner
            ? '1280:w-[calc(100%-304px)]'
            : '1280:overflow-hidden 1280:rounded-tr-lg 1280:rounded-br-lg'
        )}
      >
        <div
          className={cn(
            ['flex', 'flex-col', 'w-full', 'gap-8', 'pb-4'],
            styles.menuContainer,
            ['768:flex-row', '768:flex-wrap', '768:justify-between'],
            ['1280:p-10', '1280:pl-0', '1280:flex-row', '1280:justify-start', '1280:flex-nowrap']
          )}
        >
          {items ? <MainItemsList items={items} /> : null}

          {latestBlogPosts ? (
            <SecondaryItemsList items={latestBlogPosts} title='Blog' link='/blog' />
          ) : null}

          {latestCustomerStories ? (
            <SecondaryItemsList
              items={latestCustomerStories}
              title='Customer stories'
              link='/customer-stories'
            />
          ) : null}
        </div>
      </div>
      {banner ? <BannerWithButton banner={banner} /> : null}
    </div>
  );
};

export default ResourcesMenu;
