import React from 'react';
import Link from 'adapter/next/link';
import dynamic from 'adapter/next/dynamic';
import { cn } from '@superside/ui';
import NextImage, { type SanityImage } from '@konsus/image';
import { defaultSanityClient } from '@konsus/sanity-client';
import styles from './index.module.css';

const SanityBlockComponent: any = dynamic(
  () => import('@konsus/sanity-components/src/SanityBlockComponent')
);

export type BannerProps = {
  link?: {
    url: string;
    label: string;
  };
  bgImage: SanityImage;
  superscriptText?: string;
  headerText?: string;
  subHeaderRichText?: string;
  variant?: string;
};

interface BannerWithButtonProps {
  banner: BannerProps;
}

export const BannerWithButton: React.FC<BannerWithButtonProps> = ({ banner }) => {
  return (
    <div
      className={cn(
        'hidden',
        '1280:flex',
        '1280:bg-dark',
        '1280:w-[304px]',
        '1280:overflow-hidden',
        '1280:rounded-tr-[8px]',
        '1280:rounded-br-[8px]',
        '1280:relative',
        'dark',
        'group'
      )}
    >
      <Link legacyBehavior href={banner.link?.url || '/'} passHref>
        <a>
          <NextImage
            alt=''
            layout='fill'
            objectFit='cover'
            objectPosition='center'
            image={banner.bgImage}
            className='absolute left-0 top-0 !h-full transition-[0.5s] duration-[ease] group-hover:scale-[1.02]'
          />
          <div className='relative z-10 flex h-full flex-col justify-between p-6'>
            <div>
              {banner.superscriptText ? (
                <span className='dark:text-light text-xs font-semibold uppercase leading-normal tracking-[1.65px]'>
                  {banner.superscriptText}
                </span>
              ) : null}
              {banner.headerText ? (
                <h3 className='dark:text-light text-[23px] leading-8'>{banner.headerText}</h3>
              ) : null}
              {banner.subHeaderRichText ? (
                <div className='dark:text-light'>
                  <SanityBlockComponent
                    sanityConfig={defaultSanityClient.clientConfig}
                    blocks={banner.subHeaderRichText}
                  />
                </div>
              ) : null}
            </div>

            <span
              className={cn(
                'font-semibold',
                'dark:text-light',
                'self-start',
                'relative',
                [
                  'before:content-[""]',
                  'before:h-px',
                  'before:w-[0%]',
                  'before:bg-light',
                  'before:absolute',
                  'before:left-0',
                  'before:bottom-px'
                ],
                [
                  'group-hover:before:w-full',
                  'group-hover:before:transition-[width]',
                  'group-hover:before:duration-[0.2s]',
                  'group-hover:before:ease-[ease-in-out]'
                ],
                [
                  'after:content-[""]',
                  'after:h-2',
                  'after:w-2',
                  'after:absolute',
                  'after:right-[-15px]',
                  'after:bottom-[6px]',
                  styles.bg
                ]
              )}
            >
              {banner.link?.label || 'Sign up now'}
            </span>
          </div>
        </a>
      </Link>
    </div>
  );
};
