import React from 'react';
import Link from 'next/link';
import NextImage from '@konsus/image';
import { cn } from '@superside/ui';
import styles from '@konsus/apps-common/src/Header/HeaderDropButton/menus/HeaderSubmenu/styles.module.css';
import { menuItemClasses } from '@konsus/apps-common/src/Header/HeaderDropButton/menus/HeaderSubmenu/HeaderSubmenuItem';

export const MainItemsList: React.FC<{ items }> = ({ items }) => {
  return (
    <div className='768:w-full 1280:w-[calc(100%/3)] 1280:gap-4 flex flex-col gap-2'>
      <span className='text-white-400 text-sm font-semibold uppercase tracking-[2.1px]'>Learn</span>
      <ul
        className={cn(
          ['flex', 'flex-col', 'gap-[10px]'],
          ['768:flex-wrap', '768:max-h-40'],
          ['1280:max-h-[initial]', '1280:gap-3']
        )}
      >
        {items.map((item, index) => (
          <li key={index}>
            <Link legacyBehavior href={item.href || '/'} passHref>
              <a
                className={cn(
                  'flex',
                  'flex-col',
                  styles.menuItemButton,
                  'text-dark',
                  'leading-5',
                  'bg-transparent',
                  'border',
                  'border-transparent',
                  'rounded-lg',
                  'cursor-pointer',
                  'no-underline',
                  'group'
                )}
              >
                <div className='flex flex-row items-start gap-[7px]'>
                  {item.phIcon ? (
                    <span
                      className='text-indigo-500 [&_svg]:h-6 [&_svg]:w-6'
                      dangerouslySetInnerHTML={{ __html: item.phIcon }}
                    />
                  ) : item.icon ? (
                    <NextImage
                      image={item.icon}
                      alt={item.linkText}
                      width='24'
                      height='24'
                      placeholder={null}
                    />
                  ) : null}

                  <div className='flex flex-col gap-[5px] pt-[3px]'>
                    <div className='flex'>
                      <span className={menuItemClasses}>{item.linkText}</span>
                    </div>
                    <span className='text-dark 1280:block hidden text-sm'>
                      {item.linkDescription}
                    </span>
                  </div>
                </div>
              </a>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};
