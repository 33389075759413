import React from 'react';
import LazyHydrate from 'react-lazy-hydration';
import dynamic from 'next/dynamic';
import { Box } from '@konsus/superside-kit';
import type { HeaderLayoutProps } from '@konsus/apps-common/src/Header';
import { Loader, Toast, type Theme } from '@superside/ui';
import { HeaderVariant } from '@konsus/apps-common/src/Header/types';
import type { CtaProps } from '@konsus/lego/src/types/ctaButton';
import { type LearningCenterMenuItem } from '../ResourcesMenu/types';
import { ResourcesMenu } from '../ResourcesMenu';

export type HeaderComponentType = React.ComponentType<
  HeaderLayoutProps<{
    learningCenter: LearningCenterMenuItem;
  }>
>;

const Header = dynamic(
  () => import(/* webpackchunkName: "Header" */ '@konsus/apps-common/src/Header')
);

const Footer = dynamic(
  () => import(/* webpackchunkName: "Footer" */ '@konsus/apps-common/src/footer')
);

const PageNotFound = dynamic(
  () => import(/* webpackchunkName: "PageNotFound" */ '../PageNotFound')
);

const MediaPreviewModal = dynamic(
  () => import(/* webpackchunkName: "MediaPreviewModal" */ '../../MediaPreviewModal')
);

interface PageProps {
  id?: string;
  FooterComponent?: React.ComponentType<any>;
  header?: JSX.Element;
  footer?: JSX.Element;
  loading?: boolean;
  notFound?: boolean;
  hideHeader?: boolean;
  tinyFooter?: boolean;
  showFullFooter?: boolean;
  disableNav?: boolean;
  disableMainNav?: boolean;
  hideFooterColumns?: boolean;
  isNewWebsite?: boolean;
  headerContent?: JSX.Element;
  headerBackground?: any;
  background?: any;
  children?: any;
  data: any;
  blocks?: Array<any>;
  showFooterBanner?: boolean;
  banner?: any;
  navSettings?: NavSettings;
  light?: boolean;
  plain?: boolean;
  footerSection?: any;
  showPreviewModal?: boolean;
  previewModalTitle?: string;
  showModalWithProgressiveForm?: boolean;
  pageTheme?: Theme;
  previewModalCtaButton?: CtaProps;
}

export interface NavSettings {
  header: HeaderVariant;
  footer: 'fullFooter' | 'minimalisticFooter' | 'withoutFooter';
}

export const Page = (props: PageProps) => {
  const {
    data = {},
    navSettings,
    isNewWebsite,
    FooterComponent = Footer,
    header,
    footer,
    loading,
    notFound,
    background,
    headerContent,
    headerBackground = 'white',
    disableMainNav,
    disableNav,
    hideHeader = false,
    tinyFooter = data?.content?.tinyFooter,
    showFullFooter = data?.content?.showFullFooter,
    hideFooterColumns = false,
    showFooterBanner,
    banner,
    light = false,
    showPreviewModal = false,
    previewModalTitle,
    previewModalCtaButton,
    ...rest
  } = props;

  const headerBlock = (
    <>
      {typeof header === 'undefined' ? (
        <Header
          {...rest}
          key='header'
          background={headerBackground}
          disableNav={disableNav}
          disableMainNav={disableMainNav}
          navSettings={navSettings}
          light={light}
          data={data}
          submenuComponents={{
            learningCenter: ResourcesMenu
          }}
        >
          {headerContent}
        </Header>
      ) : (
        header
      )}
    </>
  );

  const footerBlock = (
    <>
      {typeof footer === 'undefined' ? (
        <LazyHydrate whenVisible>
          <FooterComponent
            hideColumns={hideFooterColumns}
            tinyFooter={tinyFooter}
            showFullFooter={showFullFooter}
            showFooterBanner={showFooterBanner}
            banner={banner}
            isNewWebsite={isNewWebsite}
            navSettings={navSettings}
            website='superside'
            withNewsletterForm
            withCapabilities
            withLearn
            {...rest}
            {...data.footerNav}
            {...data.skeleton.navigation.footerNavigation}
            key='footer'
          />
        </LazyHydrate>
      ) : (
        footer
      )}
    </>
  );

  return (
    <Box
      className='text-bor-foreground w-full shrink-0 grow transition-[background] duration-200 ease-in-out'
      background={background}
    >
      <Toast />
      {showPreviewModal ? (
        <MediaPreviewModal title={previewModalTitle} ctaButton={previewModalCtaButton} />
      ) : null}

      {navSettings?.header === HeaderVariant.WITHOUT_HEADER || hideHeader ? null : headerBlock}

      <div key='main' className='relative z-[4] flex shrink-0 grow flex-col print:min-h-[auto]'>
        <div className='relative z-[1] flex shrink-0 grow flex-col'>
          {loading ? <Loader /> : null}
          {notFound ? <PageNotFound /> : props.children}
        </div>
      </div>

      {navSettings?.footer === 'withoutFooter' ? null : footerBlock}
    </Box>
  );
};

export default Page;
