import React from 'react';
import { useRouter } from 'next/router';
import Head from 'next/head';
import { type HelmetPropsType } from '@konsus/apps-common';

export interface PageHelmetProps extends HelmetPropsType {
  fullTitle?: string;
  fullDescription?: string;
  canonical?: string;
}

const defaultOgImageSrc =
  'https://cdn.sanity.io/images/k0dlbavy/production/aeb47d850c005c5a7c780e8af313593f86177db1-1200x630.png?w=1200&h=630&fit=max';

export const PageHelmet: React.FC<PageHelmetProps> = (props) => {
  const { asPath } = useRouter();

  let {
    title,
    description,
    fullTitle,
    fullDescription,
    canonical,
    prev,
    next,
    ogImageSrc = defaultOgImageSrc,
    ogType,
    ogImageWidth,
    ogImageHeight,
    nofollow,
    json,
    colorTheme = '#0b1926'
  } = props;

  if (title) {
    if (!fullTitle) {
      fullTitle = `${title}`;
    }
    if (!fullDescription && (description || title)) {
      fullDescription = `${description || title}`;
    }
  }

  return (
    <Head>
      {fullTitle && <title>{fullTitle}</title>}
      {fullTitle && <meta property='og:title' content={fullTitle} />}
      {fullTitle && <meta property='og:site_name' content={fullTitle} />}
      {fullDescription ? <meta name='description' content={fullDescription} /> : null}
      {fullDescription ? <meta property='og:description' content={fullDescription} /> : null}
      {nofollow ? <meta name='robots' content='noindex, nofollow' /> : null}
      <meta property='og:type' content={ogType || 'website'} />
      {ogImageSrc || defaultOgImageSrc ? (
        <meta property='og:image' content={ogImageSrc || defaultOgImageSrc} />
      ) : null}
      <meta property='og:url' content={`https://www.superside.com${asPath}`} />
      <meta name='twitter:card' content='summary_large_image' />
      <meta name='theme-color' content={colorTheme} />

      {ogImageWidth ? <meta property='og:image:width' content={ogImageWidth.toString()} /> : null}
      {ogImageHeight ? (
        <meta property='og:image:height' content={ogImageHeight.toString()} />
      ) : null}
      {canonical ? (
        <link
          rel='canonical'
          href={
            canonical.includes('www.superside.com')
              ? canonical
              : `https://www.superside.com${canonical}`
          }
        />
      ) : null}
      {prev ? (
        <link
          rel='prev'
          href={prev.includes('www.superside.com') ? prev : `https://www.superside.com${prev}`}
        />
      ) : null}
      {next ? (
        <link
          rel='next'
          href={next.includes('www.superside.com') ? next : `https://www.superside.com${next}`}
        />
      ) : null}
      {json ? (
        <script
          type='application/ld+json'
          dangerouslySetInnerHTML={{
            __html: json
          }}
        />
      ) : null}
      {props.children}
    </Head>
  );
};

export default PageHelmet;
