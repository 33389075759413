/* eslint-disable @next/next/no-img-element */
import React from 'react';
import Link from 'adapter/next/link';
import NextImage from '@konsus/image';
import { cn } from '@superside/ui';
import { type BaseDropdownSubmenuItem } from '../../../types';
import styles from './styles.module.css';

const tagColorVariants = {
  '#FF635F': 'after:bg-coral-500 after:text-light',
  '#CBD6FE': 'after:bg-blue-200 after:text-dark',
  '#4F4FC8': 'after:bg-indigo-500 after:text-light',
  '#0F2133': 'after:bg-darker after:text-light'
};

export const HeaderSubmenuItem: React.FC<BaseDropdownSubmenuItem> = (props) => {
  const { href = '/', icon, phIcon, linkText, linkDescription, allowTag = false, tag } = props;

  const showTag = allowTag && tag?.name;

  return (
    <li>
      <div
        className={cn(
          'flex',
          'flex-col',
          styles.menuItemButton,
          'text-dark',
          'leading-5',
          'bg-transparent',
          'border',
          'border-transparent',
          'rounded-lg',
          'cursor-pointer',
          'no-underline',
          'relative',
          'group'
        )}
      >
        <div className='grid grid-cols-[24px_1fr] items-start gap-2'>
          {phIcon ? (
            <span
              className='text-indigo-500 [&_svg]:h-6 [&_svg]:w-6'
              dangerouslySetInnerHTML={{ __html: phIcon }}
            />
          ) : icon ? (
            <NextImage image={icon} alt={linkText} placeholder={null} className='h-6 w-6' />
          ) : null}

          <div className='flex flex-col gap-[5px] pt-[3px]'>
            <div className='flex'>
              <Link href={href} className={cn(menuLinkClasses)}>
                <span className={menuItemClasses}>
                  <span
                    data-testid='item-tag'
                    data-tag-name={tag?.name}
                    className={cn(
                      showTag &&
                        `after:content-[attr(data-tag-name)] ${
                          // @ts-ignore
                          tagColorVariants[tag.color || '#FF635F']
                        } after:-top-2px after:group-hover:transition-ml after:text-bor-xs after:absolute after:left-full after:ml-2 after:rounded after:px-[6px] after:py-px after:font-semibold after:uppercase after:tracking-[1.65px] after:group-hover:ml-6 after:group-hover:duration-100 after:group-hover:ease-in-out`
                    )}
                  >
                    {linkText}
                  </span>
                </span>
              </Link>
            </div>
            <span className='text-dark text-sm'>{linkDescription}</span>
          </div>
        </div>
      </div>
    </li>
  );
};

const menuLinkClasses = cn(
  'text-dark before:content-empty leading-5 before:absolute before:left-0 before:top-0 before:h-full before:w-full'
);

export const menuItemClasses = cn(
  'relative font-semibold before:absolute before:bottom-px before:left-0 before:h-px before:w-[0%] before:bg-[#0F2133] before:content-[""] after:absolute after:bottom-[6px] after:right-[-15px] after:h-2 after:w-2 after:opacity-0 after:content-[""] group-hover:no-underline group-hover:before:w-full group-hover:before:transition-[width] group-hover:before:duration-200 group-hover:before:ease-[ease-in-out] group-hover:after:opacity-100 group-hover:after:transition-opacity group-hover:after:duration-200 group-hover:after:ease-[ease-in-out]',
  styles.chevronBg
);
