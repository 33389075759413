import React from 'react';
import Link from 'next/link';
import { Heading, cn } from '@superside/ui';
import NextImage, { type SanityAsset } from '@konsus/image';
import { ChevronDownThin } from '@konsus/superside-kit/src/images/icons';
import { Icon } from '@konsus/superside-kit';
import styles from './ResourcesMenu.module.css';

interface SecondaryItemsListProps {
  items: {
    objectPosition?: string;
    image: SanityAsset;
    title: string;
    href: string;
    _id: string;
  }[];
  title: string;
  link: string;
}

export const SecondaryItemsList: React.FC<SecondaryItemsListProps> = ({ items, title, link }) => {
  return (
    <div className='768:w-[47%] 1280:w-[calc(100%/3)] 1280:items-start flex flex-col gap-4'>
      <span className='text-sm font-semibold uppercase tracking-[2.1px] text-[#646464]'>
        {title}
      </span>
      <ul className='flex flex-col gap-4'>
        {items.map((item) => (
          <li className={cn('group', styles.cover)} key={item._id}>
            <Link legacyBehavior href={item.href} passHref>
              <a className='flex flex-row items-center gap-3'>
                <div className='relative h-[72px] w-[118px] overflow-hidden rounded-lg'>
                  <NextImage
                    layout='fill'
                    objectFit='cover'
                    objectPosition={item.objectPosition || 'center'}
                    image={item.image}
                    alt={item.title}
                  />
                </div>

                <Heading
                  level='h4'
                  size='sm'
                  underline='animated'
                  className='line-clamp-3 w-[calc(100%-110px)] text-base font-semibold'
                >
                  {item.title}
                </Heading>
              </a>
            </Link>
          </li>
        ))}
      </ul>
      <Link legacyBehavior href={link} passHref>
        <a className='hover:bg-grey-200 bg-grey-100 mb-2 flex items-center justify-center rounded-lg px-4 py-2 text-center font-semibold'>
          See all
          <Icon
            icon={ChevronDownThin}
            color='currentColor'
            className='ml-2 h-3 w-3 rotate-[270deg]'
          />
        </a>
      </Link>
    </div>
  );
};
