import React from 'react';

export const ChevronDownThin = ({ color, size, ...props }) => (
  <svg width={size} height={size} viewBox='0 0 16 17' fill='none' {...props}>
    <g>
      <path
        id='Vector'
        d='M14 5L8 12L2 5'
        stroke={color}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
  </svg>
);
