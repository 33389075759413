import type React from 'react';
import { type CtaProps } from '@konsus/lego/src/types/ctaButton';

interface SanityImage {
  _type: 'image';
  asset: {
    _ref: string;
    _type: string;
  };
}

export enum HeaderVariant {
  FULL_HEADER = 'fullHeader',
  ONLY_LOGO = 'onlyLogo',
  WITHOUT_HEADER = 'withoutHeader'
}

export interface HeaderProps {
  data: any;
}

export type FeaturedVideoItem = {
  id: string;
  image: SanityImage;
  publishedAt: string;
  title: string;
  viewCount: string;
};

export interface BaseMenuItem {
  createdAt: string;
  linkText: string;
  display?: string;
  href?: string;
  target?: 'blank';
  videoStats?: FeaturedVideoItem[];
}

// Types for default dropdown manu ("Capabilities" section or "Our Teams")
export interface BaseDropdownSubmenuItem extends BaseMenuItem {
  icon: string;
  linkText: string;
  linkDescription?: string;
  href: string;
  allowTag?: boolean;
  tag?: {
    name: string;
    color?: string;
  };
}

export interface BaseDropdownMenuItem extends BaseMenuItem {
  type: string;
  data: {
    items: BaseDropdownSubmenuItem[];
    enableReadMoreButton?: boolean;
    button?: any;
  };
}

// General header types
export type HeaderNavigationDropdownMenuItem<T = any> = T | BaseDropdownMenuItem;

export enum STICKY_CTA_POSITIONS {
  TOP = 'top',
  BOTTOM = 'bottom'
}

export type StickyCtaPositionType = STICKY_CTA_POSITIONS.TOP | STICKY_CTA_POSITIONS.BOTTOM;

export interface WrapperLogoWithStickyCtaProps {
  ctaButton: CtaProps;
  isAlwaysShown?: boolean;
  firstSectionHidden?: boolean;
  lastSectionHidden?: boolean;
  heightOffset?: number;
  position?: StickyCtaPositionType;
  children: React.ReactNode;
  themeSwitchEnabled?: boolean;
  background?: string;
}
